<template>
    <AppLayout>
        <template #navigation>
            <MenuComponent :default-active="$route.path" direction="vertical" router>
                <MenuItemComponent :index="`/survey/${$route.params.id}/share`">
                    <svg
                        style="margin-left: 8px; margin-right: 15px"
                        width="17"
                        height="20"
                        viewBox="0 0 22 26"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <g stroke="#34495E" stroke-width="2" fill="none" fill-rule="evenodd">
                            <path
                                d="M15.898 11.24l4.065-4.064a3.62 3.62 0 000-5.117 3.62 3.62 0 00-5.117 0L8.692 8.213a3.62 3.62 0 001.518 6.024"
                            />
                            <path
                                d="M6.1 14.76l-4.065 4.064a3.62 3.62 0 000 5.117 3.62 3.62 0 005.118 0l6.154-6.153a3.62 3.62 0 00-1.519-6.025"
                            />
                        </g>
                    </svg>
                    <span>Link sharing</span>
                </MenuItemComponent>
                <MenuItemComponent :index="`/survey/${$route.params.id}/share/social`">
                    <svg
                        preserveAspectRatio="xMinYMid"
                        width="32"
                        height="32"
                        viewBox="0 0 64 64"
                        xmlns="http://www.w3.org/2000/svg"
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-miterlimit="1.414"
                    >
                        <g fill-rule="nonzero">
                            <path
                                d="M33.825 16.25h-3.652c-1.237 0-2.232 1.014-2.232 2.277v26.946c0 1.265.995 2.278 2.232 2.278h3.652c1.239 0 2.232-1.014 2.232-2.277V18.527c.001-1.263-.993-2.277-2.232-2.277z"
                                fill="#5ac8fa"
                            />
                            <path
                                d="M20.636 22.457h-3.654c-1.236 0-2.231 1.015-2.231 2.277v20.738c0 1.264.995 2.277 2.231 2.277h3.654c1.237 0 2.232-1.014 2.232-2.277v-20.74c0-1.264-.995-2.276-2.232-2.276z"
                                fill="#f56762"
                            />
                            <path
                                d="M47.018 32.8h-3.653c-1.238 0-2.232 1.014-2.232 2.276v10.396c0 1.264.994 2.277 2.232 2.277h3.653c1.237 0 2.232-1.014 2.232-2.277V35.076c0-1.263-.995-2.276-2.232-2.276z"
                                fill="#2ecc71"
                            />
                        </g>
                        <path
                            d="M32 29.424L18.868 16.71m26.536 6.208L32 28.96"
                            fill="none"
                            stroke="#858990"
                            stroke-width="2"
                        />
                        <path
                            d="M41.404 22.918a4 4 0 118 0 4 4 0 01-8 0zM14.868 16.71a4 4 0 118 0 4 4 0 01-8 0zm13.073 12.252a4 4 0 118-.002 4 4 0 01-8 .002z"
                            fill="#fff"
                            fill-rule="nonzero"
                            stroke="#444"
                            stroke-width="2.005"
                            stroke-linecap="butt"
                            stroke-linejoin="miter"
                        />
                    </svg>
                    <span>Social sharing</span>
                </MenuItemComponent>
                <MenuItemComponent :index="`/survey/${$route.params.id}/share/embed`">
                    <svg
                        preserveAspectRatio="xMinYMid"
                        width="32"
                        height="32"
                        viewBox="0 0 64 64"
                        xmlns="http://www.w3.org/2000/svg"
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        stroke-miterlimit="10"
                    >
                        <path
                            d="M50.984 19.695a3.518 3.518 0 013.516 3.516v26.367a3.516 3.516 0 01-3.516 3.516H13.016A3.516 3.516 0 019.5 49.578V23.211a3.516 3.516 0 013.516-3.516h37.968z"
                            fill="#2c3e50"
                            fill-rule="nonzero"
                            stroke="#ccc"
                            stroke-width="1.5"
                        />
                        <path
                            d="M50.984 10.906H13.016A3.516 3.516 0 009.5 14.422v8.789a3.516 3.516 0 013.516-3.516h37.968a3.518 3.518 0 013.517 3.516v-8.789a3.518 3.518 0 00-3.517-3.516z"
                            fill="#ccc"
                            fill-rule="nonzero"
                            stroke="#ccc"
                            stroke-width="1.5"
                        />
                        <path
                            d="M16.068 37.887l9.545 4.049v-2.621l-6.657-2.575 6.657-2.482v-2.64l-9.545 4.029v2.24zm12.737 5.828h1.967l3.485-14.043h-2.004l-3.448 14.043zm8.644-9.479l6.666 2.529-6.666 2.559v2.621l9.553-4.057v-2.225l-9.553-4.031v2.604z"
                            fill="#86a3ff"
                            fill-rule="nonzero"
                        />
                        <circle cx="13.672" cy="15.254" r="2.5" fill="#f56762" />
                        <circle cx="20" cy="15.254" r="2.5" fill="#ffba59" />
                        <circle cx="26.328" cy="15.254" r="2.5" fill="#2ecc71" />
                    </svg>
                    <span>Embed in web page</span></MenuItemComponent
                >
                <MenuItemComponent :index="`/survey/${$route.params.id}/share/popup`">
                    <svg
                        preserveAspectRatio="xMinYMid"
                        width="32"
                        height="32"
                        viewBox="0 0 64 64"
                        xmlns="http://www.w3.org/2000/svg"
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        stroke-miterlimit="10"
                    >
                        <path
                            d="M50.984 19.695a3.518 3.518 0 013.516 3.516v26.367a3.516 3.516 0 01-3.516 3.516H13.016A3.516 3.516 0 019.5 49.578V23.211a3.516 3.516 0 013.516-3.516h37.968z"
                            fill="#2c3e50"
                            fill-rule="nonzero"
                            stroke="#ccc"
                            stroke-width="1.5"
                        />
                        <path
                            d="M50.984 10.906H13.016A3.516 3.516 0 009.5 14.422v8.789a3.516 3.516 0 013.516-3.516h37.968a3.518 3.518 0 013.517 3.516v-8.789a3.518 3.518 0 00-3.517-3.516z"
                            fill="#ccc"
                            fill-rule="nonzero"
                            stroke="#ccc"
                            stroke-width="1.5"
                        />
                        <path
                            d="M42.553 28.5H21.447c-.802 0-1.447.641-1.447 1.438v13.123c0 .799.645 1.438 1.447 1.438h21.106a1.44 1.44 0 001.446-1.438V29.938a1.438 1.438 0 00-1.446-1.438z"
                            fill="#fdfdfd"
                            fill-rule="nonzero"
                        />
                        <circle cx="13.672" cy="15.254" r="2.5" fill="#f56762" />
                        <circle cx="20" cy="15.254" r="2.5" fill="#ffba59" />
                        <circle cx="26.328" cy="15.254" r="2.5" fill="#2ecc71" />
                    </svg>
                    <span>Embed popup</span>
                </MenuItemComponent>
            </MenuComponent>
        </template>
        <div class="share-view">
            <RouterView />
        </div>
    </AppLayout>
</template>

<script>
import AppLayout from '@/layouts/AppLayout'

export default {
    name: 'Share',

    components: {
        AppLayout
    }
}
</script>

<style scope lang="scss">
.share-view {
    height: auto;

    max-width: 800px;
    min-height: 100vh;
    max-height: 100vh;

    margin: 0 auto;
    padding: 0 20px;
}
</style>
